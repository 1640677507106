<template>
    <div>
        <h1>Statement {{ id }}</h1>
        <table id="statementId">
            <thead>
                <tr>
                    <th
                        v-for="(_, idx) in cols.length"
                        :key="idx"
                    >
                        {{ cols[idx] }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(_, rowIdx) in rows.length"
                    :key="rowIdx"
                >
                    <td
                        v-for="(_, colIdx) in cols.length"
                        :key="colIdx"
                    >
                        {{ rows[rowIdx][cols[colIdx]] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { httpClient } from '@/services/http-client'

    export default {
        name: 'StatementId',
        props: {
            id: {
                type: Number,
                required: true,
            },
        },
        data: function () {
            return {
                rows: [],
            }
        },
        mounted: async function () {
            const response = await httpClient.post('/avenCoreCardGetStatementMetadata', {
                statementId: this.id.toString(),
            })

            if (!response.data.success) {
                console.error(response.data.error)
                alert(response.data.error)
            }

            const stmtMdata = response.data.payload
            console.log(`/avenCoreCardGetStatementMetadata ${JSON.stringify(stmtMdata, null, 2)}`)
            this.rows.push({
                Description: 'Account Number',
                Details: stmtMdata.AccountNumber,
            })
            this.rows.push({
                Description: 'Account ID',
                Details: stmtMdata.AccountID,
            })
            this.rows.push({
                Description: 'Statement Start Date',
                Details: stmtMdata.StatementStartDate,
            })
            this.rows.push({
                Description: 'Statement End Date',
                Details: stmtMdata.StatementEndDate,
            })
            this.rows.push({
                Description: 'Statement Due Date',
                Details: stmtMdata.StatementDueDate,
            })
            this.rows.push({
                Description: 'Next Statement Date',
                Details: stmtMdata.NextStatementDate,
            })
            this.rows.push({
                Description: 'Payment Due Date',
                Details: stmtMdata.PaymentDueDate,
            })
            this.rows.push({
                Description: 'Previous Balance',
                Details: stmtMdata.PreviousBalance,
            })
            this.rows.push({
                Description: 'Current Balance',
                Details: stmtMdata.CurrentBalance,
            })
            this.rows.push({
                Description: 'Statement Purchases',
                Details: stmtMdata.StatementPurchases,
            })
            this.rows.push({
                Description: 'Current Min Payment',
                Details: stmtMdata.CurrentMinPayment,
            })
            this.rows.push({
                Description: 'Statement Min Payment',
                Details: stmtMdata.StatementMinPayment,
            })
        },
        computed: {
            cols() {
                if (this.rows.length === 0) {
                    return []
                }
                return Object.keys(this.rows[0])
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/appBase';
</style>
